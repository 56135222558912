import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { colorPalette, Typo } from 'ri-components';
import { MD_LAYOUT_BREAKPOINT } from '../../themes/GlobalThemeProvider';
import useLogout from '../../auth/useLogout';

const { grey, teal } = colorPalette;

const StyledMenu = withStyles((theme) => ({
  paper: {
    width: 180,
    borderRadius: 12,
    border: `solid 1px ${grey[3]}`,
    boxShadow: '0 4px 12px 0 #ddddde',
    [theme.breakpoints.down(MD_LAYOUT_BREAKPOINT)]: {
      width: '100%',
      maxWidth: '100%',
      borderRadius: 0,
      border: 'none',
      boxShadow: `0 12px 16px -8px ${grey[3]}`,
    },
  },
  list: {
    padding: theme.spacing(1.25, 0),
  },
}))(Menu);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: teal[10],
    fontSize: 15,
    lineHeight: 1.33,
    letterSpacing: '0.1px',
    padding: theme.spacing(1.25, 2.5),
  },
}))(MenuItem);

const useStyles = makeStyles({
  iconButton: {
    padding: 0,
    height: 32,
    width: 32,
    '&:focus': {
      outline: 'none',
    },
  },
  icon: {
    height: 32,
    width: 32,
  },
  iconSmall: {
    height: 24,
    width: 24,
  },
});

const UserMenu = ({ navbarRef }) => {
  const classes = useStyles();
  const expandIconRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(MD_LAYOUT_BREAKPOINT));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(
      !!isMobile && !!navbarRef
        ? navbarRef.current
        : expandIconRef
        ? expandIconRef.current
        : event.currentTarget,
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = useLogout();

  return (
    <React.Fragment>
      <IconButton
        aria-label='account of current user'
        aria-haspopup='true'
        color='inherit'
        disableRipple
        onClick={handleMenu}
        className={classes.iconButton}
      >
        <AccountCircle className={classes.icon} />
      </IconButton>

      <IconButton
        aria-label='current user menu'
        aria-haspopup='true'
        color='inherit'
        ref={expandIconRef}
        disableRipple
        onClick={handleMenu}
        className={classes.iconButton}
      >
        {!open && <ExpandMore className={classes.iconSmall} />}
        {!!open && <ExpandLess className={classes.iconSmall} />}
      </IconButton>

      <StyledMenu
        id='menu-appbar'
        elevation={0}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {/* Commented temporary as we don't currently have functionality built for the 'My Profile' 
        
        <StyledMenuItem>
          <Typo variant='bodySm'>My Profile</Typo>
        </StyledMenuItem>  */}
        <StyledMenuItem onClick={onLogout}>
          <Typo variant='bodySm'>Logout</Typo>
        </StyledMenuItem>
      </StyledMenu>
    </React.Fragment>
  );
};

export default UserMenu;

UserMenu.propTypes = {
  navbarRef: PropTypes.object,
};
